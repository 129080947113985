html, body {
  background-color: #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.slpercent {
  color: #888;
  position: absolute;
  bottom: 516px;
  right: 120px;
}

.night-mode {
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  position: absolute;
  top: 10px;
  right: 120px;
}

.nbbx {
  position: absolute;
  top: 30px;
  right: 1px;
}

.night-mode p, .night-mode b, .night-mode input, .night-mode label, .night-mode select, .night-mode button, .night-mode q-btn {
  vertical-align: middle;
  margin: 0;
  display: inline;
}

.night-mode select {
  display: inline-block;
}

.form-select-sm {
  font-size: 11px;
}

/*# sourceMappingURL=index.92cc6b37.css.map */
